<template>
  <div>
    <div class="lg:hidden">
      <div
        class="
          px-6
          py-2
          flex flex-col
          font-semibold
          flex-grow
          lg:mt-32 lg:min-h-50vh
        "
      >
        <header class="py-2 text-lg flex text-text-alternate-1 pr-6">
          <button
            class="focus:outline-none"
            @click="
              () => (isAddingBank ? (isAddingBank = false) : $router.go(-1))
            "
          >
            <LeftArrowIcon class="h-6 fill-current" />
          </button>
          <h2 class="mx-auto">{{ isAddingBank ? 'Add Bank' : 'Payment' }}</h2>
        </header>

        <BSpinner v-if="verificationResponse.isLoading" text="Verifying" />

        <BPageLoader
          v-if="isLoading || banksResponse.isLoading"
          class="flex-grow"
        />

        <template v-else-if="!isAddingBank">
          <div
            class="
              flex flex-col
              items-center
              pb-6
              flex-grow
              items-center
              justify-center
            "
            v-if="!data"
          >
            <span
              class="
                h-24
                w-24
                rounded-full
                bg-bg-alternate-3
                flex
                items-center
                justify-center
                my-4
              "
            >
              <BankIcon class="h-8 fill-current" style="fill: #323232" />
            </span>
            <h4 class="text-text-primary font-semibold my-4">
              No bank account
            </h4>
            <p class="text-center font-medium text-xs text-text-primary px-3">
              You have not added any withdrawal bank account. Click the button
              below to add a receiving account.
            </p>
            <button
              class="
                font-bold
                mx-auto
                max-w-lg
                w-full
                mt-8
                py-4
                text-sm text-center text-btn-text-primary
                bg-btn-bg-primary
                inline-block
                rounded
              "
              @click="isAddingBank = true"
            >
              Add Bank Account
            </button>
          </div>
          <div class="flex flex-col" v-else>
            <div
              class="border rounded border-border-primary p-4 text-xs flex my-8"
            >
              <BankIcon class="h-6 fill-current" style="fill: #323232" />
              <div class="flex flex-col justify-between px-3 pb-1">
                <p class="font-semibold text-text-primary truncate uppercase">
                  {{ data.accountname }}
                </p>
                <p class="text-text-alternate-3 font-semibold my-1">
                  {{ data.accountnumber }}
                </p>
                <p class="text-text-alternate-3 font-medium uppercase">
                  {{ data.bankname }}
                </p>
              </div>
            </div>
            <button
              class="
                bg-btn-bg-primary
                text-btn-text-primary
                p-4
                w-full
                rounded
                text-center text-sm
                font-bold
              "
              @click="isAddingBank = true"
            >
              Change Bank
            </button>
          </div>
        </template>
        <form class="py-2 text-sm" @submit.prevent="handleSubmit" v-else>
          <div class="flex flex-col my-4">
            <label class="text-text-primary font-semibold" for="bank_number"
              >Bank Account Number</label
            >
            <input
              class="
                border
                rounded
                border-border-primary
                my-2
                p-3.5
                text-sm
                font-medium
                focus:outline-none
              "
              placeholder="Account Number"
              v-model="bankNumber"
              :maxlength="10"
              required
              id="bank_number"
            />
          </div>
          <div class="flex flex-col mt-2 mb-6">
            <label class="text-text-primary font-semibold" for="bank_name">
              Choose Bank
            </label>
            <BSelectInput
              class="my-2"
              id="bank_name"
              :options="bankList"
              v-model="bank"
            />
            <p
              class="
                text-xs
                flex
                px-3.5
                font-medium
                text-text-danger
                py-2
                rounded
                mb-2
              "
              style="background: #ffd9d9"
              v-if="verificationResponse.isFailure"
            >
              <ErrorIcon class="mr-3 h-4" />
              Could not find bank account!
            </p>
            <div
              v-else-if="verificationResponse.data"
              class="border rounded border-border-primary p-3.5 text-xs flex"
            >
              <div class="bg-bg-alternate-3 h-11 rounded w-14"></div>
              <div class="flex flex-col justify-between px-3 py-1">
                <p class="text-text-primary truncate">
                  {{ verificationResponse.data.account_name }}
                </p>
                <p class="text-text-alternate-3 font-medium">
                  {{ verificationResponse.data.account_number }}
                </p>
              </div>
            </div>
          </div>

          <div class="text-center">
            <button
              class="
                bg-btn-bg-primary
                text-btn-text-primary
                rounded
                w-full
                mt-4
                py-4
                font-bold
              "
              type="submit"
              :disabled="!verificationResponse.data || updateResponse.isLoading"
            >
              <BButtonLoader
                class="h-4 w-4 mx-auto"
                v-if="updateResponse.isLoading"
              />
              <span v-else>Submit</span>
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="hidden lg:flex justify-center">
      <div class="b-card pt-8 mt-24">
        <div class="px-26 py-2 flex flex-col font-semibold flex-grow">
          <header class="py-2 text-lg flex text-text-alternate-1 pr-6">
            <button
              class="focus:outline-none"
              @click="
                () => (isAddingBank ? (isAddingBank = false) : $router.go(-1))
              "
            >
              <LeftArrowIcon class="h-6 fill-current" />
            </button>
            <h2 class="mx-auto">{{ isAddingBank ? 'Add Bank' : 'Payment' }}</h2>
          </header>

          <BSpinner v-if="verificationResponse.isLoading" text="Verifying" />

          <BPageLoader
            v-if="isLoading || banksResponse.isLoading"
            class="flex-grow"
          />

          <template v-else-if="!isAddingBank">
            <div
              class="
                flex flex-col
                items-center
                pb-6
                flex-grow
                items-center
                justify-center
              "
              v-if="!data"
            >
              <span
                class="
                  h-24
                  w-24
                  rounded-full
                  bg-bg-alternate-3
                  flex
                  items-center
                  justify-center
                  my-4
                "
              >
                <BankIcon class="h-8 fill-current" style="fill: #323232" />
              </span>
              <h4 class="text-text-primary font-semibold my-4">
                No bank account
              </h4>
              <p class="text-center font-medium text-xs text-text-primary px-3">
                You have not added any withdrawal bank account. Click the button
                below to add a receiving account.
              </p>
              <button
                class="
                  font-bold
                  mx-auto
                  max-w-lg
                  w-full
                  mt-8
                  py-4
                  text-sm text-center text-btn-text-primary
                  bg-btn-bg-primary
                  inline-block
                  rounded
                "
                @click="isAddingBank = true"
              >
                Add Bank Account
              </button>
            </div>
            <div class="flex flex-col" v-else>
              <div
                class="
                  border
                  rounded
                  border-border-primary
                  p-4
                  text-xs
                  flex
                  my-8
                "
              >
                <BankIcon class="h-6 fill-current" style="fill: #323232" />
                <div class="flex flex-col justify-between px-3 pb-1">
                  <p class="font-semibold text-text-primary truncate uppercase">
                    {{ data.accountname }}
                  </p>
                  <p class="text-text-alternate-3 font-semibold my-1">
                    {{ data.accountnumber }}
                  </p>
                  <p class="text-text-alternate-3 font-medium uppercase">
                    {{ data.bankname }}
                  </p>
                </div>
              </div>
              <button
                class="
                  bg-btn-bg-primary
                  text-btn-text-primary
                  p-4
                  w-full
                  rounded
                  text-center text-sm
                  font-bold
                "
                @click="isAddingBank = true"
              >
                Change Bank
              </button>
            </div>
          </template>
          <form class="py-2 text-sm" @submit.prevent="handleSubmit" v-else>
            <div class="flex flex-col my-4">
              <label class="text-text-primary font-semibold" for="bank_number"
                >Bank Account Number</label
              >
              <input
                class="
                  border
                  rounded
                  border-border-primary
                  my-2
                  p-3.5
                  text-sm
                  font-medium
                  focus:outline-none
                "
                placeholder="Account Number"
                v-model="bankNumber"
                :maxlength="10"
                required
                id="bank_number"
              />
            </div>
            <div class="flex flex-col mt-2 mb-6">
              <label class="text-text-primary font-semibold" for="bank_name">
                Choose Bank
              </label>
              <BSelectInput
                class="my-2"
                id="bank_name"
                :options="bankList"
                v-model="bank"
              />
              <p
                class="
                  text-xs
                  flex
                  px-3.5
                  font-medium
                  text-text-danger
                  py-2
                  rounded
                  mb-2
                "
                style="background: #ffd9d9"
                v-if="verificationResponse.isFailure"
              >
                <ErrorIcon class="mr-3 h-4" />
                Could not find bank account!
              </p>
              <div
                v-else-if="verificationResponse.data"
                class="border rounded border-border-primary p-3.5 text-xs flex"
              >
                <div class="bg-bg-alternate-3 h-11 rounded w-14"></div>
                <div class="flex flex-col justify-between px-3 py-1">
                  <p class="text-text-primary truncate">
                    {{ verificationResponse.data.account_name }}
                  </p>
                  <p class="text-text-alternate-3 font-medium">
                    {{ verificationResponse.data.account_number }}
                  </p>
                </div>
              </div>
            </div>

            <div class="text-center">
              <button
                class="
                  bg-btn-bg-primary
                  text-btn-text-primary
                  rounded
                  w-full
                  mt-4
                  py-4
                  font-bold
                "
                type="submit"
                :disabled="
                  !verificationResponse.data || updateResponse.isLoading
                "
              >
                <BButtonLoader
                  class="h-4 w-4 mx-auto"
                  v-if="updateResponse.isLoading"
                />
                <span v-else>Submit</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <BModal :isOpen="updateResponse.isSuccess" @close="handleDone">
    <div class="text-center flex flex-col items-center pb-6">
      <SuccessIcon class="my-4 mx-auto" />
      <h4 class="text-text-primary font-semibold my-3 text-lg">
        Sent for approval.
      </h4>
      <p
        class="
          text-center
          max-w-sm
          font-medium
          text-xs text-text-alternate-3
          px-3
        "
      >
        You have not added any withdrawal bank account. Click the button below
        to add a receiving account.
      </p>
      <button
        class="
          bg-btn-bg-primary
          max-w-sm
          text-btn-text-primary
          rounded
          w-full
          mt-10
          py-4
          font-bold
          text-sm
          focus:outline-none
        "
        @click="handleDone"
      >
        Done
      </button>
    </div>
  </BModal>
</template>

<script>
import { toRefs, ref, computed, watch } from 'vue';
import { useApi } from '@/cmp-functions/useApi';
import { useStore } from 'vuex';
import {
  getUserBank,
  registerBank,
  updateUserBank,
  verifyAccount,
  getBanks,
} from '@/services/api';
import BSpinner from '@/components/ui/BSpinner';
import BModal from '@/components/ui/BModal';
import BSelectInput from '@/components/ui/BSelectInput';

const userBankUpdate = ({ bankId, payload }) => {
  return bankId
    ? updateUserBank({ bankId, ...payload })
    : registerBank(payload);
};

export default {
  name: 'Payment',

  components: { BModal, BSpinner, BSelectInput },

  setup() {
    const isAddingBank = ref(false);
    const store = useStore();
    const authUser = store.state.auth.authData;
    const [response, fetchUserBank] = useApi(getUserBank);
    fetchUserBank(authUser.id);

    const bank = ref('');
    const bankNumber = ref('');

    const isFilled = computed(() => {
      return bankNumber.value.length === 10 && bank.value.length > 0;
    });

    const [banksResponse, fetchBank] = useApi(getBanks);
    fetchBank(authUser.country);
    const bankList = computed(
      () => banksResponse.data?.map(({ name }) => name) || []
    );

    const [updateResponse, updateBank, { setIsSuccess }] =
      useApi(userBankUpdate);
    const [
      verificationResponse,
      verifyBankAccount,
      { setIsFailure: setIsVerificationFailure },
    ] = useApi(verifyAccount);

    const handleDone = () => {
      const { code: bankcode, name: bankname } = bankDetails.value;
      const updatedData = {
        userid: authUser.id,
        bankcode,
        bankname,
        accountnumber: bankNumber.value,
        accountname: verificationResponse.data.account_name,
      };
      setIsSuccess(false);
      response.data = updatedData;
      isAddingBank.value = false;
    };

    const bankDetails = computed(() =>
      banksResponse.data.find(({ name }) => name === bank.value)
    );

    const handleSubmit = () => {
      if (isFilled.value) {
        const { code: bankcode, name: bankname } = bankDetails.value;
        const payload = {
          userid: authUser.id,
          bankcode,
          bankname,
          accountnumber: bankNumber.value,
          accountname: verificationResponse.data.account_name,
        };
        updateBank({ payload, bankId: response.data?.id });
      }
    };

    watch(bankNumber, () => {
      handleVerification();
    });

    watch(bank, () => {
      handleVerification();
    });

    const handleVerification = () => {
      setIsVerificationFailure(false);
      if (!isFilled.value) return;
      verificationResponse.data = null;
      const { code: bankcode } = bankDetails.value;
      verifyBankAccount({
        account_bank: bankcode,
        account_number: bankNumber.value,
      });
    };

    return {
      ...toRefs(response),
      isAddingBank,
      updateResponse,
      banksResponse,
      verificationResponse,
      setIsVerificationFailure,
      bankNumber,
      bank,
      bankList,
      handleVerification,
      handleDone,
      handleSubmit,
    };
  },
};
</script>
